var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "campaign" }, [
      _c("a", { attrs: { href: "/campaigns/" + _vm.campaign.id } }, [
        _vm._v(_vm._s(_vm.campaign.name))
      ])
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "drl" }, [_vm._v(_vm._s(_vm.campaign.drl))]),
    _vm._v(" "),
    _c("td", { staticClass: "status" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default btn-xs",
          class: _vm.status,
          on: { click: _vm.processCampaignAssets }
        },
        [
          _vm._v(_vm._s(_vm.status) + " "),
          _c("i", { staticClass: "zmdi zmdi-refresh" })
        ]
      )
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "start" }, [_vm._v(_vm._s(_vm.campaign.start))]),
    _vm._v(" "),
    _c("td", { staticClass: "last-update" }, [
      _vm._v(_vm._s(_vm.campaign.last_update))
    ]),
    _vm._v(" "),
    _vm.queue > 0
      ? _c("td", { staticClass: "queue" }, [
          _c("i", { staticClass: "alerts" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "/campaigns/" +
                    _vm.campaign.id +
                    "/assets?filter=unmoderated"
                }
              },
              [_vm._v(_vm._s(_vm.queue))]
            )
          ])
        ])
      : _c("td", { staticClass: "queue" }, [_vm._v("-")]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "more" }, [
      _c("ul", { staticClass: "actions" }, [
        _c("li", { staticClass: "dropdown action-show" }, [
          _c("a", { attrs: { href: "", "data-toggle": "dropdown" } }, [
            _c("i", { staticClass: "zmdi zmdi-more-vert" })
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu dm-icon pull-right" }, [
            _c("li"),
            _vm._v(" "),
            _c("li")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }