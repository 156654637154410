var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-default btn-xs",
        class: _vm.status,
        on: { click: _vm.processCampaignAssets }
      },
      [
        _vm._v(_vm._s(_vm.status) + " "),
        _c("i", { staticClass: "zmdi zmdi-refresh" })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }