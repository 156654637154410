var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.queue > 0
    ? _c("i", { staticClass: "alerts" }, [
        _vm.queue > 0
          ? _c(
              "a",
              {
                attrs: {
                  href:
                    "/campaigns/" +
                    _vm.campaign.id +
                    "/assets?filter=unmoderated"
                }
              },
              [_vm._v(_vm._s(_vm.queue))]
            )
          : _vm._e()
      ])
    : _c("span", [_vm._v("-")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }