var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-secondary",
        attrs: { type: "button" },
        on: { click: _vm.processAssets }
      },
      [_vm._v("Process Assets")]
    ),
    _vm._v(" "),
    _vm.processing
      ? _c("div", { staticClass: "spinner" }, [
          _c("div", { staticClass: "rect1" }),
          _vm._v(" "),
          _c("div", { staticClass: "rect2" }),
          _vm._v(" "),
          _c("div", { staticClass: "rect3" }),
          _vm._v(" "),
          _c("div", { staticClass: "rect4" }),
          _vm._v(" "),
          _c("div", { staticClass: "rect5" })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.error_message
      ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.error_message))])])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }