var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [
      !_vm.processing
        ? _c(
            "button",
            {
              staticClass: "btn btn-default btn-xs",
              on: { click: _vm.processAssets }
            },
            [_vm._v("Process All Campaigns")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.processing
      ? _c(
          "div",
          { staticClass: "alert alert-light", attrs: { role: "alert" } },
          [
            _vm._v("\n        Processing "),
            _c("strong", [_vm._v(" " + _vm._s(_vm.name))])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }